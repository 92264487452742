<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/cycle' }">巡检计划</el-breadcrumb-item>
          <el-breadcrumb-item>计划详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡检代号：" prop="taskCode">
                {{ form.taskCode }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起止日期：" prop="name">
                {{ form.startDate? dayjs(form.startDate).format('YYYY-MM-DD'):'' }} ~ {{ form.endDate? dayjs(form.endDate).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 巡视点信息配置 -->
          <div class="form-title">巡视点信息</div>
          <el-table :data="patrols" style="width: 100%;margin-bottom: 20px;">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
          </el-table>

          <!-- 巡检人员 -->
          <div class="form-title">巡检人员配置</div>
          <el-row :gutter="20">
            <el-col :span="24" class="lubri-col">
              <div class="lubri-tabs">巡检周期配置</div>
              <el-tabs v-model="activeName">
                <el-tab-pane label="自定义" name="custom" disabled>
                  <div v-for="(item, key) in ruleStaffDetail" :key="key" class="config-box">
                    <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 自定义配置</span>
                      </div>
                    </div>
                    <el-form-item label="巡检员：" prop="name">
                      <div class="staffBox">
                        <el-tag v-for="staff in item.staffInfos" :key="staff.staffId" style="margin-right: 4px;">
                          {{ staff.staffName }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <div class="setWeek">
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-row :gutter="20" class="cycles">
                            <el-col :span="20">
                              <el-form-item label="首次工作日期：" label-width="130px">
                                {{ item.frequencyInfos[0].firstDate?dayjs(item.frequencyInfos[0].firstDate).format('YYYY-MM-DD'):'-' }}
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="7">
                          <el-row :gutter="20" class="cycles">
                            <el-col :span="20">
                              <el-form-item label="工作天数：">
                                <el-input v-model="item.frequencyInfos[0].workDay" disabled></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="1" style="padding-left:0;">
                              <el-form-item label="天" label-width="0px"></el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="7">
                          <el-row :gutter="20" class="cycles">
                            <el-col :span="20">
                              <el-form-item label="间隔天数：">
                                <el-input v-model="item.frequencyInfos[0].restDay" disabled></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="1" style="padding-left:0;">
                              <el-form-item label="天" label-width="0px"></el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <div class="setWeek">
                        <el-form-item label="工作时间：">
                          <span v-for="(tItem, tIndex) in item.frequencyInfos[0].frequencyTimeInfos" :key="tIndex" style="margin-right:20px;">
                            {{ dateSecond(tItem.startTime) }} ~ {{ dateSecond(tItem.endTime) }}
                          </span>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="周配置" name="weekConfig" disabled>
                  <div v-for="(item, key) in ruleStaffDetail" :key="key" class="config-box">
                    <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 周配置 {{key + 1}} </span>
                      </div>
                    </div>
                    <el-form-item label="巡检员：" prop="name">
                      <div class="staffBox">
                        <el-tag v-for="staff in item.staffInfos" :key="staff.staffId" style="margin-right: 4px;">
                          {{ staff.staffName }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <div class="setWeek">
                      <el-form-item label="工作时间：">
                        <div class="box" v-for="(fItem, fIndex) in item.frequencyInfos" :key="fIndex">
                          <div class="week-title">
                            <span v-if="fItem.weekIndex == 0">星期日</span>
                            <span v-if="fItem.weekIndex == 1">星期一</span>
                            <span v-if="fItem.weekIndex == 2">星期二</span>
                            <span v-if="fItem.weekIndex == 3">星期三</span>
                            <span v-if="fItem.weekIndex == 4">星期四</span>
                            <span v-if="fItem.weekIndex == 5">星期五</span>
                            <span v-if="fItem.weekIndex == 6">星期六</span>
                            <span v-for="(tItem, tIndex) in fItem.frequencyTimeInfos" :key="tIndex">
                              {{ dateSecond(tItem.startTime) }} ~ {{ dateSecond(tItem.endTime) }}
                            </span>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/cycle')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      form: {
        startDate: 0,
        endDate: 0,
      },
      patrols: [],
      row: {},
      // tabs
      activeName: "custom",
      ruleStaffDetail: []
    }
  },
  methods: {
    dayjs,
    dateSecond(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
    },
    loadBaseDetail() {
      this.$ajax.post('rulebaseDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        sessionStorage.setItem("detail", true);
        this.$set(this.form, 'taskRuleName', res.data.taskRuleName)
        this.form.startDate = res.data.startDate
        this.form.endDate = res.data.endDate
        this.form.taskCode = res.data.taskCode
        this.form.departmentName = res.data.departmentName
        this.loadPatrolDetail()
      })
    },

    loadPatrolDetail() {
      this.loading = true
      this.$ajax.post('rulePatrolDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        res.data.forEach(v=>{
          this.patrols.push({
            departmentName: this.form.departmentName,
            patrolName: v.patrolName
          })
        })
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },

    loadStaffDetail() {
      this.$ajax.post('ruleStaffDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        // this.$set(this.form, 'patrols', this.patrolsString(res.data))
        this.ruleStaffDetail = res.data
      })
    },

    patrolsString(arr) {
      let d = []
      if (arr) {
        arr.forEach(item => {
          d.push(item.patrolName)
        })
      }
      return d.toString();
    }
    // rulePatrolDetail
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/cycle')
      return
		}
    this.row = this.$route.params.row
    if(this.row.frequencyType==0) {
      this.activeName = 'weekConfig'
    }else {
      this.activeName = 'custom'
    }
    this.loadBaseDetail()
    this.loadStaffDetail()
  },
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-title {
  font-weight: bold;
}
.config-box {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin: 20px 0;
  .title {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
  }
}
.staffBox {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  margin-right: 140px;
  padding: 4px;
  min-height: 40px;
}
.setWeek {
  border-top: 0;
  .week-title {
    font-size: 14px;
    span {
      margin-right: 20px;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 0 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
.lubri-col /deep/.el-tabs__nav {
  left: 140px;
}
.lubri-col /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
.lubri-tabs {
  font-size: 14px;
  font-weight: bold;
  color: #606266;
  line-height: 40px;
  margin-left: 30px;
  position: absolute;
}
.cycles /deep/.el-input__inner {
  text-align: center;
}
</style>